<template>
    
<div id="expansion_page">
   

    <md-dialog :md-active.sync="show_door_desc">
      <md-dialog-title>מה זה גודל הדלת?</md-dialog-title>

      <div class="pop_info_inner" v-html="get_word('door_size_desc')">
          
      </div>

      <md-dialog-actions>
        <md-button class="md-primary" @click="show_door_desc = false">סגירה</md-button>
      </md-dialog-actions>
    </md-dialog>

     <md-dialog :md-active.sync="show_height_alert">
      <md-dialog-title>נא לבחור גובה הסוכה!</md-dialog-title>
      <md-dialog-actions>
        <md-button class="md-primary" @click="show_height_alert = false">סגירה</md-button>
      </md-dialog-actions>
    </md-dialog>


    <md-dialog :md-active.sync="show_desc">
      <md-dialog-title>מה ההבדל בין המסגרות?</md-dialog-title>

      <div class="pop_info_inner">
        <div id="frames_pics" class="row">

            <div class="col-md-4">

                <div class="pic" :style="get_thumb(5624, 240, 165)"></div>
                <label>מסגרת מסורת</label>
                <span>מסגרת בסיסית ללא תמיכה
                רק ברזל למעלה ולמטה</span>
            </div>

            <div class="col-md-4">

                <div class="pic" :style="get_thumb(5623, 240, 165)"></div>
                <label>מסגרת מהדרין מחוזק</label>
                <span>מסגרת עם תמיכה של מוט נוסף לאורך כל הסוכה</span>

            </div>

            <div class="col-md-4">

                <div class="pic" :style="get_thumb(5622, 240, 165)"></div>
                <label>מסגרת מהדרין ברזל</label>
                <span>מסגרת עם תמיכה של 4 מוטות נוספים לאורך כל הסוכה</span>

            </div>
        </div>

      </div>

      <md-dialog-actions>
        <md-button class="md-primary" @click="show_desc = false">סגירה</md-button>
      </md-dialog-actions>
    </md-dialog>

   <div class="row">

    <h1 class="title2">מה גודל הסוכה שיש לכם עכשיו?</h1>
    <label>כל המידות במטרים</label>

    <div class="line">
        
        <div class="col-md-3 col-xs-12 dim_wrap" data-axis="length">
                
            <label>אורך הסוכה</label>
            <SizeInp :order="order" :value="data.prev_x" axis="length" v-model="data.prev_x" ref="sizeLength" :isPrev="true"></SizeInp>
                
        </div>
        <div class="col-md-3 col-xs-12 dim_wrap" data-axis="width">

            <label>רוחב הסוכה</label>
            <SizeInp :order="order" :value="data.prev_y" axis="width" v-model="data.prev_y" ref="sizeWidth" :isPrev="true"></SizeInp>
 
        </div>
            
        <div class="col-md-3 col-xs-12" data-axis="height">
            <label>גובה הסוכה</label>

            <md-field>
                <label>בחר גובה סוכה</label>
                <md-select v-model="data.motot_height">
                    <md-option value="2.3">2.3 מטר</md-option>
                    <md-option value="2.1">2.1 מטר</md-option>
                </md-select>
            </md-field>


        </div>
        
    </div>

    <div class="line">
        <label>בחירת מיקום וגודל הדלת</label>
        <div class="col-md-3 col-xs-12" id="door_location">
        
            <md-field>
                <label>מיקום הדלת</label>
                <md-select v-model="data.prev_door_location" @md-selected="make_door_size">
                    <md-option value="length">באורך הסוכה</md-option>    
                    <md-option value="width">ברוחב הסוכה</md-option>  
                </md-select>
            </md-field>

        </div>
        <div class="col-md-3 col-xs-12" id="door_size" v-if="data.prev_door_location != ''">


            <md-field>
                <label>גודל הדלת</label>
                <md-select v-model="data.prev_door_size">
                    <md-option v-for="(door,key) in doorSizeOpts" :key="key" :value="String(key)">{{door.title}}</md-option>
                </md-select>
            </md-field>
        </div>
        
            <div class="clr"></div>
            <a @click="show_door_desc=true" class="bold" id="door_size_desc" style="display: block; margin-top: 15px; padding: 0px;">מה זה גודל הדלת?</a>

    </div>
    
    <div class="line">
        

     <label>איזה סוג מסגרת יש לסוכה שלכם?</label>
     <div class="btns_list">
   <div :class="'button radio ' + (data.prev_frame == 'masoret' ? 'active' : '')" @click="data.prev_frame = 'masoret'">מסגרת מסורת</div>
   <div :class="'button radio ' + (data.prev_frame == 'mhuzak' ? 'active' : '')" @click="data.prev_frame = 'mhuzak'">מסגרת מהדרין מחוזק</div>
   <div :class="'button radio ' + (data.prev_frame == 'barzel' ? 'active' : '')" @click="data.prev_frame = 'barzel'">מסגרת מהדרין ברזל</div>
   </div>
        <div class="clr"></div>
        <a @click="show_desc=true" class="bold fancy" style="padding: 0px;">מה ההבדל בין המסגרות?</a>
   
    </div>

     
     <div class="bottom_area">
      
        <a class="back" href="/">חזרה</a>
      <input :class="'button white ' + (isActiveSave ? '' : 'disabled')" :disabled="!isActiveSave" value="המשך" type="submit" @click="save">
      
     </div>
     
    
   </div>

</div>
</template>


<script type="text/javascript">
import SizeInp from '@/components/sizeInp.vue';

export default {
components: {SizeInp },
  props: ["order"],
  data() {
      return {
        loaded: false,
        show_desc: false,
        show_height_alert: false,
        show_door_desc: false,
        data: {
            prev_x: (this.isNull(this.order.prev_x) ? 1.5 : this.order.prev_x),
            prev_y: (this.isNull(this.order.prev_y) ? 1.5 : this.order.prev_y),
            prev_door_location: this.order.prev_door_location,
            prev_door_size: this.order.prev_door_size,
            prev_frame: this.order.prev_frame,
            motot_height: this.order.motot_height
        },

        doorSizeOpts: {}
          
      }
  },
  computed:{
      isActiveSave(){
          return !(this.data.prev_door_location == '' || this.data.prev_door_size == '' || this.data.prev_frame == '');
        
      }
  },
  mounted() {
      console.log(this.data.prev_door_location)
      if (this.data.prev_door_location != '') 
      {
          setTimeout(() => { this.make_door_size(); }, 1000)
          
      }
  },
  methods:{
       save(){
        let data = this.data;
        if(!(data.motot_height > 0))
        {
            this.show_height_alert = true;
            return false; 
        }
        data.id = this.order.id;
        this.net_api({action: 'order/save_expansion', data: data}, () => {
            this.$emit('updatePage', 'dimensions');
        });
    },



    make_door_size( /*saved_sizes*/ ){ 
        if (this.data.prev_door_location == '') return false;
        let sizes;
        let all = true;
        
        //if (!this.$refs.sizeLength.activePlan) return;

        if (this.data.prev_door_location == "length" )
        {
            if (this.isNull(this.$refs.sizeLength) || this.isNull(this.$refs.sizeLength.activePlan)) sizes = this.order.rods_plan.length;
            else sizes = this.$refs.sizeLength.activePlan.rods
    
            if (this.data.length <= 3) all = false;
        } 
        if (this.data.prev_door_location  == "width" )
        {
            if (this.isNull(this.$refs.sizeWidth) || this.isNull(this.$refs.sizeWidth.activePlan)) sizes = this.order.rods_plan.width;
            else sizes = this.$refs.sizeWidth.activePlan.rods

            if (this.data.length <= 3) all = false;
        } 

        //sizes = saved_sizes || sizes;

        this.net_api({ action: 'order/door_prev_size_inp', data: { sizes: sizes, all: all}}, (data) => {

            this.doorSizeOpts = data.data;

        });
    },


  }

}

/*
    if ( length_val && width_val )
    {
        api_call({ url: 'order/get_rods_plan_prev', data: { id: '{MISC::$GLOBALS["id"]}' }, onComplete: function(data){

              make_plan(data.data["x"], "#length_rods");
              make_plan(data.data["y"], "#width_rods");

        }});

        $.post("home/calc_dim?pmode=empg&id={MISC::$GLOBALS["id"]}", { size: length_val }, function(data){

            var data = JSON.parse(data);
            var obj = $("select[name='prev_x']");
            length = data;

            // list of plans
            var length_list = html_template('home/dim_list', { data: data} );
            $(obj.closest('.dim_wrap').find('.rods_dropdown')).html(length_list);

        });


        $.post("home/calc_dim?pmode=empg&id={MISC::$GLOBALS["id"]}", { size: width_val }, function(data){

            var data = JSON.parse(data);
            var obj = $("select[name='prev_y']");
            width = data;
            // list of plans
            var length_list = html_template('home/dim_list', { data: data} );
            $(obj.closest('.dim_wrap').find('.rods_dropdown')).html(length_list);

        });

    }



    
    $("#expansion_page select[name='prev_x'], #expansion_page select[name='prev_y']").on("change", function(){
        
        $(".plan").show();
        $(".rods_dropdown").hide();

        var obj = $(this);
        
        $.post("home/calc_dim?pmode=empg&id={MISC::$GLOBALS["id"]}", { size: $(this).val() }, function(data){
            
            var data = JSON.parse(data);
        
            if ($(obj).attr('name') == "prev_x") length = data;
            else width = data;
        
            make_plan(data[0], obj.closest('.dim_wrap').find('.plan'), (data.length > 1) );
  
            //save to db first plan
            var settings = {
                id: '{MISC::$GLOBALS["id"]}',
                axis: $(obj).closest('.dim_wrap').data('axis'),
                data: data[0]
            }
            api_call({ url: 'order/save_rods_prev', data: settings});
            
            // list of plans
            var length_list = html_template('home/dim_list', { data: data} );
            $(obj.closest('.dim_wrap').find('.rods_dropdown')).html(length_list);
  
   make_door_size();
            
        });

    });
    
    // door size by input
    $('#expansion_page').on("change", "select[name='prev_door_location']", function(){
        
        make_door_size();
        
    });
        
    $("#expansion_page").on("click", "#plan_change", function(){
        
        $(this).closest(".rods").find(".plan").hide();
        $(this).closest(".rods").find(".rods_dropdown").show();
        
    });
    
 
    
});
    

*/
</script>

<style lang="scss" scoped>
.btns_list{
    .button { display: inline-block; margin-left: 10px; width:auto !important; }
}
</style>