<template>
<div id="side_bar" class="def_bg">
    <div class="logo" style="display: inline-block;">סוכות נחלים</div>
    <div class="logo_subtitle">מחשבון סוכה</div>
    
    <div class="hide_in_mobile" id="side_bar_logo"><img src="@/assets/quality.png" style="width: 50%; "></div>
    
    <div v-if="$route.name != 'summary' && $route.name != 'pay' && $route.name != 'CreateOrder' && $route.name != 'print'" class="hide_in_mobile" id="side_price" v-text="format_number(total)"></div>
      
    
</div>
</template>

<script>
export default {
  props: ["order"],
  data() { 
    return {
      total: 0
    }
  },
  methods: {
    init(){
      
      this.net_api({ action: 'order/get_summary', data: {id: this.order.id} }, (data)=> {
        if (!this.isNull(data.data))
        {
          this.total = data.data.total_price_final;
        }
          this.loaded = true;
      });
    }
  },
  mounted(){
    this.init();
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#side_bar { /*float: right;*/ width: 20%; padding-top: 80px; position: absolute; right: 0px; top: 0px; bottom: 0px; text-align: center; }
#side_bar .logo {  font-weight: 400; 
  @media (min-width: 768px) {margin-bottom: 25px; font-size: 54px; }
  @media (max-width: 768px) { font-size: 45px; }
}
#side_price { position: fixed; top: calc(100vh - 100px); font-size: 50px; width: 240px; /* left: 0px; right: 0px;*/ }
@media (min-width: 768px)
{
  .logo_subtitle { margin-bottom: 30px;}
  #side_price { position: fixed; top: calc(100vh - 100px); font-size: 50px; width: 240px; }
}
#side_price:before {
    content: "₪";
    font-size: 20px;
}

@media (max-width: 768px)
{
  .logo_subtitle { margin-top: -16px;}
  #side_bar { position: fixed; top: 0px; left:0px; right: 0px; bottom: auto; padding: 0px; width: auto; z-index: 999;}
}

</style>