<template>

<div id="calc_menu" class="hide_in_mobile" v-if="!isShare && !isPaid">
<ul :data-count="menu.length">
	
    <li v-for="(item,i) in menu" :key="item.page"  :data-page="item.page" :class="(i <= currStep ? 'active' : '')">
    <router-link :to="'/' + item.page + '/' + order.id" class="menu_icons"><div class="icon" :style="'mask-image: url(' + item.icon + ')'"></div></router-link>
    <label v-text="item.title"></label>
    </li>

<div id="strip" :style="'width: ' + 100 / (menu.length-1) * currStep + '%'"></div>
</ul>
</div>
</template>

<script>
export default {
    props: ["order"],
  data(){
      let ret = {
        menu: [
            {title:'מידות',icon: require('../assets/size.png'),page:'dimensions',more_pages:['expansion'], active: true},
            {title:'מסגרת',icon:require('../assets/box.png'),page:'frame', active: false},
            {title:'בד',icon:require('../assets/paper.png'),page:'walls', active: false},
            {title:'סכך',icon:require('../assets/tree.png'),page:'thatch', active: false},
            {title:'אביזרים',icon:require('../assets/plus.png'),page:'accessories', active: false},
            {title:'סיכום',icon:require('../assets/v.png'),page:'summary', active: false},
        ]
      }

        if (this.order.is_expansion == '1') ret.menu.unshift({title:'הרחבה',icon: require('../assets/size.png'),page:'expansion', active: true})
        
        if (this.order.is_accessories == '1') 
        {
            for (let i in ret.menu){
                ret.menu[i].active = false;
                if (ret.menu[i].page == 'accessories') ret.menu[i].active = true;
                
            }
            /*
            ret.menu = [
            
                {title:'אביזרים',icon:require('../assets/plus.png'),page:'accessories', active: false},
                {title:'סיכום',icon:require('../assets/v.png'),page:'summary', active: false},
            ]*/
        }


      return ret;
  },
  methods:{
      init(){
        for (let i in this.menu)
        {
            this.menu[i].active = (this.$route.name == this.menu[i].page);
        }
      }
  },
  mounted(){
      this.init();
  },
  computed:{
      isPaid(){
          return (this.order.is_paid == '1');
      },

      isShare(){
          return (/*this.order.source == 'site' &&*/ !this.is_logged())
      },
      currStep(){
          for (let i in this.menu)
          {
              if (this.menu[i].active) return i;
          }

          return 0;
      }

  },
  watch: {
    '$route' () {
        
        this.init();

      // react to route changes...
    }
  }
}
</script>

<style lang="scss" scoped>

#calc_menu { padding:  40px 80px; position: absolute; left: 0px; top: 0px; right: 240px; z-index: 5; }
#calc_menu ul { overflow: hidden; position: relative; margin: 0px;

    display: flex;
    justify-content: space-between;
}
#calc_menu ul:after { content: ""; display: block; border-top:5px solid #DBDBDB; position: absolute; left: 0%; right: 0%; top: 20px;  }
#calc_menu ul li { display: block; float: right; /*width: 16.66666666666667%;*/ text-align: center; }
/*#calc_menu ul[data-count="7"] li { width: 14.28571428571429%;}
#calc_menu ul[data-count="2"] li { width: 50%;}
*/
#calc_menu ul li a { display: block; width: 42px; height: 42px; margin: 0 auto; background: #DBDBDB center center no-repeat; border-radius: 100%; position: relative; z-index: 9;background-size: 60%; padding: 0px;}
#calc_menu ul li a .icon { display: block; width: 42px; height: 42px; mask-size: 60%; mask-repeat: no-repeat; mask-position: center center; background: #333; }
#calc_menu ul li label { font-size: 15px; }
#calc_menu ul li.active a { display: block; width: 42px; height: 42px; background-color: #5EA219;
    .icon { background: #fff;}
}
#calc_menu ul li.active label { color: #5EA219; }
    #calc_menu ul #strip { display: block; position: absolute;right: 0%; top: 20px;border-top: 5px solid #5ea219; z-index: 2;}

</style>