<template>
<div id="walls" v-if="loaded">
   <div class="row">
    <div class="col-md-6 rods">
        <h1 class="title2">עיצוב דפנות הסוכה</h1>
        <div :class="'button white full ' + (isAllClean ? 'active' : '')" @click="all_clean">בחירת ״בד חלק״ לכל הסוכה? </div>
        <div class="line">
            <div class="col-md-6 col-xs-2">
                <div class="pic" :style="'background-image: url(' + img.length_1 + ')'"></div>
            </div>
            <div class="col-md-6 col-xs-10">
                <label>דופן אורך ראשונה: <span>{{order.length}} מטר {{(order.door_location == 'length' ? 'עם דלת' : '')}}</span></label>
                
                <div v-for="inp in inps.length1" :key="inp.name">
                    <label v-text="inp.title"></label>
                    <div class="inp_sub_title" v-text="inp.sub_title"></div>

                    <md-field>
                        <label>{{inp.label}}</label>
                        <md-select v-model="inp.value">
                             <template v-if="inp.options && inp.options[0] && inp.options[0].id > 0">
                                <md-option v-for="opt in inp.options" :key="opt.id" :value="opt.id">{{opt.name}}</md-option>
                            </template>
                            <template v-else>
                                <md-option v-for="(opt, optI) in inp.options" :key="optI" :value="optI">{{opt}}</md-option>
                            </template>        
                        </md-select>
                    </md-field>
<!-- 
                    <select v-model="inp.value">
                        <option v-for="(opt, optI) in inp.options" :key="optI" :value="optI">{{opt}}</option>
                    </select> -->
                </div>
                <!-- {$form->print_group("length1", "mtr")} -->
            </div>
        </div>
        <div class="line">
            <div class="col-md-6 col-xs-2">
                <div class="pic" :style="'background-image: url(' + img.length_2 + ')'"></div>
            </div>
            <div class="col-md-6 col-xs-10">
                <label>דופן אורך שנייה: <span>{{order.length}} מטר</span></label>
                
                <div v-for="inp in inps.length2" :key="inp.name">
					<label v-text="inp.title"></label>
					<div class="inp_sub_title" v-text="inp.sub_title"></div>
			

                    <md-field>
                        <label>{{inp.label}}</label>
                        <md-select v-model="inp.value">
                            <template v-if="inp.options && inp.options[0] && inp.options[0].id > 0">
                                <md-option v-for="opt in inp.options" :key="opt.id" :value="opt.id">{{opt.name}}</md-option>
                            </template>
                            <template v-else>
                                <md-option v-for="(opt, optI) in inp.options" :key="optI" :value="optI">{{opt}}</md-option>
                            </template>
                        </md-select>
                    </md-field>

<!-- 
                    <select v-model="inp.value">
                        <option v-for="(opt, optI) in inp.options" :key="optI" :value="optI">{{opt}}</option>
                    </select> -->
                </div>

            </div>
        </div>

        <div class="line">
            <div class="col-md-6 col-xs-2">
                <div class="pic" :style="'background-image: url(' + img.width_1 + ')'"></div>
            </div>
            <div class="col-md-6 col-xs-10">
                    <label>דופן רוחב ראשונה: <span>{{order.width}} מטר {{(order.door_location == 'width' ? 'עם דלת' : '')}}</span></label>
                    
                    <div v-for="inp in inps.width1" :key="inp.name">
                        <label v-text="inp.title"></label>
                        <div class="inp_sub_title" v-text="inp.sub_title"></div>
                        
                        
                    <md-field>
                        <label>{{inp.label}}</label>
                        <md-select v-model="inp.value">
                            <template v-if="inp.options && inp.options[0] && inp.options[0].id > 0">
                                <md-option v-for="opt in inp.options" :key="opt.id" :value="opt.id">{{opt.name}}</md-option>
                            </template>
                            <template v-else>
                                <md-option v-for="(opt, optI) in inp.options" :key="optI" :value="optI">{{opt}}</md-option>
                            </template>
                        </md-select>
                    </md-field>

                        <!-- <select v-model="inp.value">
                            <option v-for="(opt, optI) in inp.options" :key="optI" :value="optI">{{opt}}</option>
                        </select> -->
                    </div>
            </div>        
        </div>
         
         <div class="line">
             <div class="col-md-6 col-xs-2">
                <div class="pic" :style="'background-image: url(' + img.width_2 + ')'"></div>
            </div>              
            <div class="col-md-6 col-xs-10">
                <label>דופן רוחב שנייה: <span>{{order.width}} מטר</span></label>
                
                    <div v-for="inp in inps.width2" :key="inp.name">
                        <label v-text="inp.title"></label>
                        <div class="inp_sub_title" v-text="inp.sub_title"></div>


                    <md-field>
                        <label>{{inp.label}}</label>
                        <md-select v-model="inp.value">
                           <template v-if="inp.options && inp.options[0] && inp.options[0].id > 0">
                                <md-option v-for="opt in inp.options" :key="opt.id" :value="opt.id">{{opt.name}}</md-option>
                            </template>
                            <template v-else>
                                <md-option v-for="(opt, optI) in inp.options" :key="optI" :value="optI">{{opt}}</md-option>
                            </template>
                        </md-select>
                    </md-field>
                    
                        <!-- <select v-model="inp.value">
                            <option v-for="(opt, optI) in inp.options" :key="optI" :value="optI">{{opt}}</option>
                        </select> -->
                    </div>

            </div>
         </div>
        
    </div>
    <div class="image_zoom_back" v-if="zoom_image != ''">
        <div class="image_zoom" >
        <div class="close" @click="zoom_image = ''">X</div>
        <div class="pic materialboxed" :style="'background-image: url(' + zoom_image + ')'" @click="zoom_image = item.pic"></div>
        </div>
    </div>
    <div class="col-md-6 side"> 
  
        <label style="text-align: center; margin-bottom: 15px; padding-left: 15px;">שימו לב: רק הדגם החלק והדגם המודפס מגיעים עם חלון מובנה בבד.</label>

  <div v-for="item in pics" :key="item.id" class="col-md-6 col-xs-6 clothes">
   <div class="pic materialboxed" :title="item.name" :style="'background-image: url(' + item.pic + ')'" @click="zoom_image = item.pic"></div>
   <label v-text="item.name"></label>
  </div>
     
     
    </div>

 </div>
    <div class="clr"></div>
    <div v-if="bad_roll.length > 0" class="row" style="text-align: center;">
            
        <label class="bold">או לחילופין - ניתן לבחור בד היקפי</label>
        
        <div class="warning" v-if="data.wall_type > 0 && ((order.length == 2 && order.width == 3 && order.door_location == 'length' && order.door_size == 2) || (order.length == 3 && order.width == 4 && order.door_location == 'length' && order.door_size == 3))">שים לב שבבד היקפי כשהכניסה בדופן הקצרה יתכן שהחלונות יהיו ממוקמים על העמודים הפינתיים</div>

        
        <div class="line">
            <div class="bad_hekef_wrap">
                    
                <div v-for="rollItem in bad_roll" :key="rollItem.id" class="bad_hekef col-xs-12">
                    
                    <div :class="'check_box ' + (data.wall_type == rollItem.id ? 'active' : '')" @click="(data.wall_type == rollItem.id ? data.wall_type='' : data.wall_type=rollItem.id)">
                        <div class="pic" :style="'background-image: url(' + rollItem.pic_url + '); background-position: center;'"></div>
                        <div id="text"><label v-text="rollItem.name"></label></div>
                        <div id="price"><span>₪</span><label class="bold" v-text="rollItem.price"></label></div>
                    </div>
                    
                    <a @click="descPopup.content = rollItem.short_text; descPopup.show=true" class="bold fancy" style="display: block; margin-top: 5px;">הסבר</a>

                </div>
                        
            </div>
        </div>



    </div>
    
     <div class="bottom_area">
        <a @click="$emit('updatePage', 'frame')" class="back">חזרה</a>
        <!-- <input :class="'button white ' + (isActiveSave ? '' : 'disabled')"  :disabled="!isActiveSave" value="המשך" type="submit" @click="save"> -->
        <input :class="'button white ' "  value="המשך" type="submit" @click="save">
     </div>
     
    
    <div class="clr"></div>


    
    <md-dialog :md-active.sync="descPopup.show">
      <md-dialog-title>{{descPopup.title}}</md-dialog-title>

      <div class="pop_info_inner" v-html="descPopup.content">
          
      </div>

      <md-dialog-actions>
        <md-button class="md-primary" @click="descPopup.show = false">סגירה</md-button>
      </md-dialog-actions>
    </md-dialog>

    

</div>
</template>

<script>

export default {
  props: ["order"],
  data() {
      return {
          loaded: false,
          show_desc: false,
          bad_roll: {},
          pics: [],
          zoom_image:'',
          data: {
              wall_type: this.order.wall_type,
          },
          descPopup: {
              show: false,
              title: '',
              content: ''

          },
          inps: [],
          img: {
              length_1: require ('../assets/length_1.png'),
              length_2: require ('../assets/length_2.png'),
              width_1: require ('../assets/width_1.png'),
              width_2: require ('../assets/width_2.png'),

              win_sml: require ('../assets/win_sml.jpg'),
              miznah: require ('../assets/2X2_1.jpg'),
              bad_modpas: require ('../assets/bad_modpas.jpg'),
              skotch: require ('../assets/2X2_8.jpg'),
          }
      }
  },
  mounted(){
      this.net_api({action: 'walls', data: {id: this.order.id}}, (data) => {
          this.pics = data.data.pics;

        //   for (let i in data.data.inps)
        //   {
        //     const firstKey = Object.keys(data.data.inps[i])[0];
        //     const firstValue = data.data.inps[i][firstKey];
        //     if(firstValue.options)
        //     {
        //         let transformedOptions = {};
        //         for (let z in firstValue.options)
        //         {
        //             const id = firstValue.options[z].id;
        //             const name = firstValue.options[z].name;
        //             if(name != '' && id != '')
        //             {
        //                 transformedOptions[id] = name;
        //             }
        //         }
        //         if(transformedOptions !== {})
        //         {
        //             firstValue.options = transformedOptions;
        //         }   
        //     }
        //   }
          this.inps = data.data.inps;

          
          this.bad_roll = data.data.bad_roll;
          this.loaded = true;
      })
  },
  
  methods:{
  
     async save(){
        let canProssed = true;
        if(!this.isActiveSave) {
            const result = await this.$swal.fire({
                    title: 'שים לב, לא בחרתם בדים לסוכה',
                    html: 'האם להמשיך בכל זאת ללא בדים?',
                    showDenyButton: true,
                    allowOutsideClick: false,
                    showCancelButton: false,
                    confirmButtonText: "כן המשך",
                    denyButtonText: `ביטול`
                });

                if (result.isConfirmed)
                {
                    canProssed = true;
                } 
                else if (result.isDenied) {
                    canProssed = false;
                    return;
                }
        }

        if (!canProssed) return;
        let inps = {};

          for (let i in this.inps)
            {
                for (let z in this.inps[i])
                {
                    inps[z] = this.inps[i][z].value;
                }
            }
        

          this.net_api({action: 'order/save_walls', data: {id: this.order.id, inps: inps, wall_type: this.data.wall_type}}, () => {
            this.$emit('updatePage', 'thatch');
        });
      },

      all_clean(){
          if (this.isAllClean)
          {
            for (let i in this.inps)
            {
                for (let z in this.inps[i])
                {
                     if (this.inps[i][z].class == 'normal_wall')
                    {
                        this.inps[i][z].value='';
                    }
                    else
                    {
                        this.inps[i][z].value=0;
                    }
                }
            }

          }
          else
          {
            for (let i in this.inps)
            {
                for (let z in this.inps[i])
                {
                    if (this.inps[i][z].class == 'normal_wall')
                    {
                        this.inps[i][z].value=8;
                    }
                    else
                    {
                        switch(this.inps[i][z].class) {
                            case "1.5":
                            this.inps[i][z].value = 1424;
                            break;
                            case "2":
                            this.inps[i][z].value = 1429;
                            break;
                            case "2.5":
                            this.inps[i][z].value = 1432;
                            break;
                            case "3":
                            this.inps[i][z].value = 1438;
                            break;

                        }

                    }
                }
            }

          }
      }
  
  },
  computed: {
      
      isActiveSave(){

        if (this.data.wall_type > 0) return true;

          let ret = true;
           for (let i in this.inps)
           {
               for (let z in this.inps[i])
               {
                   if (this.inps[i][z].value == '') ret = false;
               }
           }
          return ret;
      },
      isAllClean(){
          
          for (let i in this.inps)
          {
              for (let z in this.inps[i])
              {
                  if (this.inps[i][z].value!=8) return false;
              }
          }

          return true;
      }

  }
}
/*
    
    $("#walls").on("click", "#all_same", function(){

        var select_door = $("select[name='door_cloth']");
        var door_size = select_door.attr("class");
  
        if ( $(this).hasClass("active") )
        {
            
            $(this).removeClass("active");
            $(".normal_wall").val("");
            select_door.val(0);
        }
        else
        {
            switch(door_size) {
                case "1.5":
                this.inps[i][z].value = 1424");
                break;
                case "2":
                this.inps[i][z].value = 1429");
                break;
                case "2.5":
                this.inps[i][z].value = 1432");
                break;
                case "3":
                this.inps[i][z].value = 1438");
                break;

            }

            $(".normal_wall").val(8);
            $(this).addClass("active");
            
        }

        scroll_to($('.bottom_area'));

 });

 {if MISC::$GLOBALS["is_expansion"] == "1" } $('.frmUL:empty').append('<li>ללא שינוי</li>');
 {else} $('.frmUL:empty').append('<li>כניסה פתוחה ללא בד</li>'); {/if}
    
});

function check_walls()
{
    if ($('select:not([name="old_door_replace"])').filter(function(){ return $(this).val() == ''; }).length > 0)
    {
        if ($('input[name="wall_type"]').val() >= 1)
            {
                
            }
        else return confirm('לא בחרת בדים. האם להמשיך?');
    }
    return true;
    
}
*/
</script>

<style lang="scss" scoped>

#walls  .mustChar { display: none; }
#walls .rods label span { color: #5ea219; display: inline-block;}
#walls .rods .pic {background-size: contain;padding-right: 0px; height: 100px; width: 100px; max-width: 100%; margin: 0 auto;    background-position: center;}
#walls .clothes { padding-right: 0px; margin-bottom: 10px;}
#walls .clothes .pic { 	height: 134.4px; margin-bottom:5px;background-position: center;}

#walls .rods label span { display: inline;}

.line { display: table; margin: 30px 0px; width: 100%;}
.line label { display: block; margin-bottom: 10px;}
.line .col-md-6 { padding-right: 0px;}
label { display: block; color: #282b34; font-size: 20px; line-height: 26px; }
.inp_sub_title { color: #282B34;	font-size: 16px;line-height: 19px;text-align: right; margin-bottom: 10px;}
select { margin-bottom: 15px;}
.image_zoom_back{position: fixed;background: rgba(0,0,0,0.2);top: 0;bottom: 0;right: 0;left: 0;z-index: 999999999;padding-top: 150px;}
.image_zoom{height: 380px;width: 40%;;background: #fff;z-index: 999999999;;margin: 0 auto;position: relative;border-radius: 12px;padding: 20px;}
.image_zoom{
    .close{font-weight: 800;position: absolute;right: 10px;top: 10px;cursor: pointer;}
    .pic{    height: 100%;width: 100%;background-size: cover;background-position: center;}
}

.bad_hekef_wrap { 
    & { text-align: center; }
    .bad_hekef { display: inline-block; width: 50%; text-align: right; margin-bottom: 25px;}
}

.warning { color:#c71b1b; font-weight: bold;}

@media only screen and (max-width: 800px) {
    .image_zoom{width: 90%;height: 250px;}
}
</style>