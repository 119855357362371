<template>
    <div v-if="loaded">
        <div id="accessories">

            <h1 class="title2">אביזרים נוספים לסוכה</h1>
            <div class="wrapper">
                <div class="bar">
                    <div id="search">
                        
                        <input v-model="search_q" placeholder="חפש מוצרים">
                        
                        
                    </div>
                    <div  v-for="cat in cats" :key="cat.id" v-text="cat.name" @click="cat.isActive=!cat.isActive" :class="'right_bar line '+(cat.isActive ? 'active' : '')"></div>
                </div>

                <div class="items" >
                    <div class="item" v-for="item in itemsComp" :key="item.id">
                        <div :class="(item.pic > 0 ? 'pic' : 'default_image pic')" :style="'background-image: url('+item.pic_parsed+')'"></div>
                                
                            
                        <div class="quantity noselect">
                            <a class="btn_more arrow" @click="increase(item)">+</a>
                            <input type="text" min="0" v-model="item.quantity" :data-item="item.id" @input="save(item)">
                            <a class="btn_less arrow" @click="decrease(item)">-</a>
                        </div>

                        <label>₪{{item.price}}</label>
                        <h4>{{item.name}}</h4>
                        <div class="short_text">{{item.short_text}}</div>
                    </div>
                    <div class="clr"></div>
                </div>
            </div>
            <!-- <div class="line" v-for="cat in cats" :key="cat.id">
                <h3 @click="cat.is_opened = !cat.is_opened">{{cat.name}} <i class="fas fa-arrow-down"></i></h3>
                <div class="inner_line" v-if="cat.is_opened" :key="cat.is_opened">          
                    <div class="row item" v-for=" item in cat.items" :key="item.id">
                        <div class="col-md-3 col-xs-12">
                                <div class="pic" :style="'background-image: url('+item.pic_parsed+')'"></div>
                        </div>
                        <div class="col-md-9 col-xs-12">
                            <label>₪{{item.price}}</label>
                            <h4>{{item.name}}</h4>
                            <div class="short_text">{{item.short_text}}</div>

                            <div class="quantity">
                            <a class="btn_more arrow" @click="increase(item)">+</a>
                            <input type="text" min="0" v-model="item.quantity" :data-item="item.id" >
                            <a class="btn_less arrow" @click="decrease(item)">-</a>
                            </div>
                        </div>
                        <div class="clr"></div>
                    </div>
                </div>

            </div> -->



        </div>

        <div class="col-md-12 col-xs-12"> 

            <div class="bottom_area">

                <a @click="$emit('updatePage', 'thatch')" class="back">חזרה</a>
                <input class="button white" value="המשך" type="submit" @click="$emit('updatePage', 'summary');">

            </div>
        </div>
    </div>
</template>
<script>

export default {
      props: ["order"],

    data() {
        return{
            cats:[],
            loaded:false,
            items:[],
            search_q: ''
        }
    },
    computed:{
        itemsComp(){
            let activeCats = this.cats.filter((x) => { return (x.isActive); });
            if (activeCats.length == 0)
            {
                return this.items.filter((x) => {
                
                   return (this.search_q == '' || x.name.search(this.search_q) !== -1);
                })
            }

            return this.items.filter((x) => {
                
                for (let i in activeCats)
                {
                    if (x.cat_id == activeCats[i].id) 
                    {
                        return (this.search_q == '' || x.name.search(this.search_q) !== -1);
                    }
                }
                
                return false;
            })
        }
    },
    methods:{
        save(item){
            this.net_api({action: 'shop/add_item', data: {order_id: this.order.id,item_id:item.id,qty:item.quantity}}, () => {
                this.$emit('refreshTotal');
            });
        },
        increase(item){
             if(item.quantity < 1000)
            {
                item.quantity++;
                this.save(item);
            }
        },
        decrease(item){
            if(item.quantity > 0)
            {
            item.quantity--;
            this.save(item);
            }
        }
    },
     mounted(){

      this.net_api({action: 'shop/results', data: {order_id: this.order.id}}, (data) => {

          this.net_api({action: 'shop/categories'}, (data) => {

              for (let i in data.data) data.data[i].isActive = false;
              this.cats = data.data;
                this.loaded = true;
          });

        this.items= data.data.items;
      });
        
  },
}
</script>
<style lang="scss" scoped>

#accessories
{
    .bar{
        @media (min-width: 768px) {
            flex-basis: 20%;
        }
        @media (max-width: 768px) {
            flex-basis: 100%;
        }
        
    }
    .wrapper{display: flex;flex-wrap: wrap;}
    .line{cursor: pointer;
    
        @media (min-width: 768px) {
            display: table;margin: 1px 0px; width: 100%;padding: 10px;
        }
        @media (max-width: 768px) {
            display: inline-block; width: auto; padding: 10px 15px; margin:0px;
        }
    }
    .items{display: flex;flex-wrap: wrap; 
        @media (min-width: 768px) {
            flex-basis: 80%;
        }
        @media (max-width: 768px) {
            flex-basis: 100%;
        }
    }
    .item{ padding: 0 10px; margin-bottom: 25px;text-align: center;
        @media (min-width: 768px) {
            flex-basis: 33.33%;
        }
        @media (max-width: 768px) {
            flex-basis: 50%;
        }

        .pic { margin-bottom: 10px;}
        
    }
    input{    height: 30px;text-align: center;width: 40px;border-radius: 0;outline: none;}
    .arrow:hover{transform: scale(1.05);transition: 0.2s all ease-in-out;}
    .arrow{height: 30px;width: 30px;background: linear-gradient(136.51deg, #c5cd1e 0%, #5ea219 100%);color: #fff;font-size: 35px;line-height: 30px;display: inline-block;text-align: center;vertical-align: middle;transition: 0.2s all ease-in-out;}
    .line h3 { width: 200px; border-bottom: 1px solid #ccc; cursor: pointer; margin-bottom: 18px;    font-size: 42px;position: relative;}
    .item .pic {height: 90px;background-size: cover;}
    .item label {font-size: 22px;line-height: 20px;margin-top: 10px;font-weight: bold;letter-spacing: 0.03px;}
    .item label span { font-size: 14px;	letter-spacing: 0.02px;}
    .item h4 {font-size: 18px;	font-weight: normal;	letter-spacing: 0.02px;	line-height: 19px;margin-bottom: 10px;}
    .item .short_text {max-width: 70%;font-size: 14px;letter-spacing: 0.02px;line-height: 17px;}
    .fa-arrow-down{position: absolute;left: 0;top: 14px; font-size: 20px;}
    .active{background: #5ea219;color: #fff;}

    #search { 
        input { width:100%; border:1px solid rgba(0, 0, 0, 0.3);  text-align: right; padding:0 10px; }
     }
}

</style>