<template>

    <div id="dimensions" v-if="loaded">

    <md-dialog :md-active.sync="show_door_desc">
      <md-dialog-title>מה זה גודל הדלת?</md-dialog-title>

      <div class="pop_info_inner" v-html="get_word('door_size_desc')">
          
      </div>

      <md-dialog-actions>
        <md-button class="md-primary" @click="show_door_desc = false">סגירה</md-button>
      </md-dialog-actions>
    </md-dialog>

    <div class="row">

    <div class="col-md-6">

        <h1 class="title2" v-if="order.is_expansion == '1'">הגדלת סוכה</h1> 
        <h1 class="title2" v-else>בחירת מידה</h1> 
        <label>כל המידות במטרים</label>

        <div class="line" v-if="order.is_expansion== '0'">
            <div class="col-md-6 col-xs-12 dim_wrap">
                <label>אורך הסוכה</label>
                <SizeInp :order="order" :value="data.length" :min="min" axis="length" v-model="data.length" ref="sizeLength" @change="check_1_5" @refreshTotal="$emit('refreshTotal'); make_door_size();"></SizeInp>
            </div>
            <div class="col-md-6 col-xs-12 dim_wrap">
                <label>רוחב הסוכה</label>
                <SizeInp :order="order" :value="data.width" :min="min" axis="width" v-model="data.width" ref="sizeWidth" @change="check_1_5" @refreshTotal="$emit('refreshTotal'); make_door_size();"></SizeInp>
            </div>
        </div>

        <div class="line" v-if="order.is_expansion== '1'">
            <div class="col-md-6 col-xs-12 dim_wrap">
                <label>אורך הסוכה</label>
                <SizeInpEX :order="order" :value="data.length" :plansAll="plansEx" :key="forceUpdateSizeKey" @save="save_dim_ex" @updateDimensions="updateDimensions" :min="min" axis="length" v-model="data.length" ref="sizeLength" @change="check_1_5" @refreshTotal="$emit('refreshTotal'); make_door_size();"></SizeInpEX>
            </div>
             
            <div class="col-md-6 col-xs-12 dim_wrap">
                <label>רוחב הסוכה</label>
                <SizeInpEX :order="order" :value="data.width" :plansAll="plansEx" :key="forceUpdateSizeKey" @save="save_dim_ex" @updateDimensions="updateDimensions" :min="min" axis="width" v-model="data.width" ref="sizeWidth" @change="check_1_5" @refreshTotal="$emit('refreshTotal'); make_door_size();"></SizeInpEX>
            </div>

            <div class="error" v-show="data.width > data.length">רצוי להזין את הדופן הארוכה יותר בשדה האורך</div>
            <div class="error" v-show="data.length * data.width < order.prev_x * order.prev_y">בחרת סוכה קטנה מהסוכה המקורית</div>
            <div class="error" v-show="data.length * data.width == order.prev_x * order.prev_y">בחרת סוכה זהה לסוכה המקורית</div>
           

        </div>
        

        <div class="line" v-if="order.is_expansion== '1'">
            <label class="bold">האם תרצו לשנות את מיקום או גודל הדלת</label>
            <div class="col-md-6 col-xs-12">
                <div :class="'button radio small ' + (data.is_change_door_location == '1' ? 'active' : '')" @click="data.is_change_door_location='1'">כן</div>
            </div>
            <div class="col-md-6 col-xs-12">
                <div :class="'button radio small ' + (data.is_change_door_location == '0' ? 'active' : '')"  @click="data.is_change_door_location='0'">לא</div>
            </div>
        </div>

		<div class="line" v-show="order.is_expansion == '0' || data.is_change_door_location == '1'">
		
			<label>בחירת מיקום וגודל הדלת</label>
			<div class="col-md-6 col-xs-12" id="door_location">
                
                <md-field>
                    <label>מיקום הדלת</label>
                    <md-select v-model="data.door_location" @md-selected="make_door_size">
                        <md-option value="length">באורך הסוכה</md-option>    
                        <md-option value="width">ברוחב הסוכה</md-option>    
                    </md-select>
                </md-field>

            </div>
			<div class="col-md-6 col-xs-12" id="door_size" v-if="data.door_location != ''">

                <md-field>
                    <label>גודל הדלת</label>
                    <md-select v-model="data.door_size">
                        <md-option v-for="(door,key) in doorSizeOpts" :key="key" :value="key">{{door.title}}</md-option>
                    </md-select>
                </md-field>

            </div>
            <div class="clr"></div>
            <a class="bold" id="door_size_desc" @click="show_door_desc=true" style="display: block; margin-top: 15px; padding: 0px;">מה זה גודל הדלת?</a>
        </div>

        <div class="line" v-show="show_1_5">
                
            <label>כל 2 מוטות 1.5 יקבלו בד באורך 3 מטר.</label>
            <div class="col-md-6 col-xs-12" id="">
                <select name="wall_merge_1_5" style="" size="1" tabindex="-1" v-model="data.wall_merge_1_5">
                    <option selected="" value="1">חבר בדים</option>
                    <option value="0">השאר בדים ברוחב 1.5 מ'</option>
                </select>
            </div>
  
        </div>
        
    </div>
    <div class="col-md-6 col-xs-12">

        <div id="loaction_door" :class="(data.length == data.width ? 'square': '') + ' ' + (data.door_location != 'width' ? 'door_y' : '') + ' ' + (data.door_size == 'all' ? 'door_all' : '')">
            <span id="paint_door">מיקום הדלת</span>
            <span id="paint_y" v-text="data.length"></span>
            <span id="paint_x" v-text="data.width"></span>
        </div>

    </div>


    <div class="bottom_area">

        <a v-if="order.is_expansion == '1'" class="back" @click="$emit('updatePage', 'expansion')">חזרה</a>
        
        <a v-else class="back" href="/">חזרה</a>
        <input :class="'button white ' + (isActiveSave ? '' : 'disabled')" :disabled="!isActiveSave" value="המשך" type="submit" @click="save">

    </div>

    <div class="clr"></div>

</div>

</div>
</template>


<script>
import SizeInp from '@/components/sizeInp.vue';
import SizeInpEX from '@/components/sizeInpEX.vue';

export default {
    components: {SizeInp, SizeInpEX },
  props: ["order"],
  data() {
      return {
        loaded: false,
        forceUpdateSizeKey: 0,
        show_door_desc: false,
        show_1_5: false,
        plansEx: { 
            length: null,
            width: null
        },
        data: {
            length: (this.order.is_expansion == '1' && this.order.length == '0' ? this.order.prev_x : this.order.length ),
            width: (this.order.is_expansion == '1' && this.order.width == '0' ? this.order.prev_y : this.order.width ),
            door_location: this.order.door_location,
            door_size: this.order.door_size,
            wall_merge_1_5: this.order.wall_merge_1_5,
            is_change_door_location: this.order.is_change_door_location
        },

        doorSizeOpts: {}
          
      }
  },
  mounted() {


    if (this.order.is_expansion == '1') this.updateDimensions(true);
      
/*
      this.net_api({ action: 'dimensions', data: { id: this.order.id }}, (data) => {

            this.doorSizeOpts = data.data.door_size;
            this.loaded=true;
        });
*/
 this.loaded=true;
      if (this.data.door_location != '') this.make_door_size();
  },
  computed:{
      isActiveSave(){
          if (this.order.is_expansion == '1')
          {
              //if (this.data.door_location == '' || this.data.door_size == '') return false;
              return true;
          }
          else {
              if (this.data.door_location == '' || this.data.door_size == '') return false;
          }
          
          return true;
      },
      min(){
          if (this.order.is_expansion == '0') return 1.5;
          
          return (this.order.prev_x < this.order.prev_y ? this.order.prev_x : this.order.prev_y);
          
      }
  },
  methods:{
      
    updateDimensions(isInit){
/*
       if (this.data.width > this.data.length)
       {
           this.alert('רצוי להזין את הדופן הארוכה יותר בשדה האורך');
       }
*/
       if (!(this.data.width > 0 && this.data.length > 0)) return;
        
        this.net_api({action: 'order/exp_calc_dim', data: {id: this.order.id, width: this.data.width, length: this.data.length}}, (data)=>{
            
            data = data.data;

            this.plansEx.length = data.x;
            this.plansEx.width = data.y;


            if (data.is_switch === true)
            {
                let origWidth = parseFloat(this.data.width);
                this.data.width = this.data.length;
                this.data.length = origWidth;

                this.forceUpdateSizeKey++;
                
                //this.updateDimensions()
                
                this.$refs.sizeWidth.save_rods();
                this.$refs.sizeLength.save_rods();
                
                return;
            }

            if (isInit === true)
            {
                this.sizeFirtInt = true;
                for (let axisI in ['width', 'length'])
                { 
                    let axis = ['width', 'length'][axisI]
         
                    for (let i in this.plansEx[axis])
                    {
                        if (this.order.rods_plan[axis])
                        { 
                            let isEx = true;
                            for (let z in this.plansEx[axis][i].rods)
                            {
                                if (this.int(this.plansEx[axis][i].rods[z]) != this.int(this.order.rods_plan[axis][z])  ) 
                                { 
                                    isEx = false;
                                    break;
                                }
                            }
                       
                            if (isEx)
                            {
                                if (axis == 'length') this.$refs.sizeLength.selectPlan(this.plansEx[axis][i]);
                                else this.$refs.sizeWidth.selectPlan(this.plansEx[axis][i]);
                            }
                        }
                    }
                }

                this.loaded=true;
            }

            this.$refs.sizeWidth.save_rods();
            this.$refs.sizeLength.save_rods();
        });


    },
      save_dim_ex(){

      },
    make_door_size( /*saved_sizes*/ ){ 

        if (this.data.door_location == '') return false;
        let sizes;
        let all = true;
        
        //if (!this.$refs.sizeLength.activePlan) return;

        if (this.data.door_location == "length" )
        {
            if (this.isNull(this.$refs.sizeLength) || this.isNull(this.$refs.sizeLength.activePlan)) sizes = this.order.rods_plan.length;
            else sizes = this.$refs.sizeLength.activePlan.rods
    
            if (this.data.length <= 3) all = false;
        }
        if (this.data.door_location == "width" )
        {
            if (this.isNull(this.$refs.sizeWidth) || this.isNull(this.$refs.sizeWidth.activePlan)) sizes = this.order.rods_plan.width;
            else sizes = this.$refs.sizeWidth.activePlan.rods

            if (this.data.length <= 3) all = false;
        } 

        //sizes = saved_sizes || sizes;
 
        this.net_api({ action: 'order/door_size_inp', data: { order_id: this.order.id, sizes: sizes, all: all}}, (data) => {

            this.doorSizeOpts = data.data;
            
            this.$emit('refreshTotal');
/*
            var prev_val = $('select[name="door_size"]').val();
                $("#door_size").html(data.data);
            
            if ($('select[name="door_size"] option[value="' + prev_val + '"]').length > 0) $('select[name="door_size"]').val(prev_val);
                
            $('select[name="door_size"]').formSelect();
*/
        });
    },

    save(){
        let data = this.data;
        data.id = this.order.id;
        this.net_api({action: 'order/save_dimensions', data: data}, () => {
            this.$emit('updatePage', 'frame');
            
            this.$emit('refreshTotal');
        });
    },
    check_1_5(){
    
        if (!this.$refs.sizeWidth.activePlan || !this.$refs.sizeLength.activePlan) return false;
//console.log(this.$refs.sizeWidth.activePlan.rods)
        this.show_1_5 = (this.$refs.sizeWidth.activePlan.rods['1_5'] > 1 || this.$refs.sizeLength.activePlan.rods['1_5'] > 1);
          /*
            var x_key = $('#length_drop ul li.active').data('key');
            var y_key = $('#width_drop ul li.active').data('key');

            if ( (!isNull(length[x_key]) && length[x_key]['1_5'] > 1) || (!isNull(length[y_key]) && width[y_key]['1_5'] > 1) )
            {
                $('select[name="wall_merge_1_5"]').closest('.line').show();
            }
            else
            {
                $('select[name="wall_merge_1_5"]').closest('.line').hide();
            }*/
        }
  }

}
</script>

<style lang="scss" scoped>

#dimensions 
{
    #loaction_door {position: relative; max-width: 100%;border: 1px solid #5ea219;
        
        @media (min-width: 768px) {
            height: 305px; 
            width: 100%;
        }
        @media (max-width: 768px) {
            height: 175px; 
            width:280px;
        }
    }
    #loaction_door span {position: absolute;border-radius: 47.06px;background-color: #5ea219;font-size: 18px;line-height: 20px;font-weight: bold;padding: 0px 15px;color: #fff;  font-family:'almoni-tzar', Arial, sans-serif !important;}
    #loaction_door #paint_door {top: -9px;left: 60px;}
    #loaction_door #paint_y {right: -19px;top: 50%;margin-top: -10px;}
    #loaction_door #paint_x {bottom: -10px;right:  50%;margin-right: -15px;}

    #loaction_door.square { width: 305px;
        @media (max-width: 768px) {
            height: 280px; 
        }
    }
    #loaction_door.door_y #paint_door { left: -46px;top: 30%;margin-top: -10px;  transform: rotate(-90deg) }
    
    #loaction_door.door_all:not(.door_y) #paint_door { left: 50%;   transform: translate(-50%, 0%); } 
    #loaction_door.door_all.door_y #paint_door { top: 50%; }
}

</style>