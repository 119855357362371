<template>
<div id="frame" v-if="loaded">
   

    <md-dialog :md-active.sync="show_desc_frame">
      <md-dialog-title>מה ההבדל בין המסגרות?</md-dialog-title>

      <div class="pop_info_inner">
        <div id="frames_pics" class="row">

            <div class="col-md-4">

                <div class="pic" :style="get_thumb(5624, 240, 165)"></div>
                <label>מסגרת מסורת</label>
                <span>מסגרת בסיסית ללא תמיכה
                רק ברזל למעלה ולמטה</span>
            </div>

            <div class="col-md-4">

                <div class="pic" :style="get_thumb(5623, 240, 165)"></div>
                <label>מסגרת מהדרין מחוזק</label>
                <span>מסגרת עם תמיכה של מוט נוסף לאורך כל הסוכה</span>

            </div>

            <div class="col-md-4">

                <div class="pic" :style="get_thumb(5622, 240, 165)"></div>
                <label>מסגרת מהדרין ברזל</label>
                <span>מסגרת עם תמיכה של 4 מוטות נוספים לאורך כל הסוכה</span>

            </div>
        </div>

      </div>

      <md-dialog-actions>
        <md-button class="md-primary" @click="show_desc_frame = false">סגירה</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="show_desc">
      <md-dialog-title></md-dialog-title>

      <div class="pop_info_inner" v-html="get_word('lavood_desc')">
          
      </div>

      <md-dialog-actions>
        <md-button class="md-primary" @click="show_desc = false">סגירה</md-button>
      </md-dialog-actions>
    </md-dialog>

   <div class="row">
       <div class="col-md-4 col-xs-12 side">

            <h1 class="title2">בחירת מסגרת</h1>
           
            <label>כל המסגרות בנויות ממתכת קלה וחזקה</label>
            <label class="bold">החל מגודל 2x3 ומעלה מומלץ לבחור מסגרת מהדרין מחוזק או מהדרין ברזל </label>
            
            <div v-if="order.is_expansion == '1' && order.prev_frame == 'barzel'">
                המסגרת שלך:<br><strong>מסגרת מהדרין ברזל</strong>
            
                <label class="bold">מכמה מוטות הלבוד שלך מורכב?<!-- (<a class="u" id="barzel_desc">הסבר</a>)--></label>
                <div style="width: 100px">
                    <select v-model="data.prev_frame_num_barzel">
                        <option value="3">3</option>
                        <option value="4">4</option>
                    </select>
                </div>
            </div>
            <div v-else>

                <div v-if="order.is_expansion == '0' || (order.is_expansion == '1' && order.prev_frame == 'masoret')" :class="'button radio flex ' + (data.frame=='masoret' ? 'active' : '')" data-pic="{STORAGE::get_thumb(5624, 'auto', 500)}" @click="data.frame='masoret'" v-text="order.prev_frame == 'masoret' ? 'להשאיר מסגרת מסורת ללא חיזוק' : 'מסגרת מסורת'"></div>
                <span v-if="order.prev_frame == 'masoret'" style="font-size: 14px">להשאיר מסגרת מסורת ללא חיזוק</span>
                
            
                <template v-if="order.is_expansion == '0' || (order.is_expansion == '1' && (order.prev_frame == 'masoret' || order.prev_frame == 'mhuzak') )">
                    <div @click="data.frame='mhuzak'" :class="'button radio flex ' + (data.frame=='mhuzak' ? 'active' : '')" v-text="(order.is_expansion == '0' || order.prev_frame == 'mhuzak' ? 
                        (order.prev_frame == 'mhuzak' ? 'להשאיר ' : '') +
                        'מסגרת מהדרין מחוזק' : 'לשדרג את כל הסוכה למסגרת מהדרין מחוזק'
                        )"></div>
                
                    <span v-if="order.is_expansion == '1' && order.prev_frame != 'mhuzak'" style="font-size: 14px">תוספת מוט חיזוק לאורך כל הסוכה</span>
                </template>
                    
                <div  @click="data.frame='barzel'" :class="'button radio flex ' + (data.frame=='barzel' ? 'active' : '')" v-text="(order.is_expansion == '0' ? 'מסגרת מהדרין ברזל' : 'לשדרג את כל הסוכה למסגרת מהדרין ברזל')"></div>
                <span v-if="order.is_expansion == '1'" style="    font-size: 14px;">תוספת 4 מוטות חיזוק לאורך כל הסוכה, בנוסף החלפת כל עמודי הגובה לסוכה המקורית</span>
            
                <a @click="show_desc_frame=true" class="bold" style="display: block; margin-top: 25px;">מה ההבדל בין המסגרות?</a>
        </div>
    </div>
       
    <div class="col-md-8 col-xs-12">
        
		<div class="pic"><span :style="'background-image: url(' +  $store.siteUrl + bigPic + ');'"></span>
        </div>
    </div>
    
    <div class="clr"></div>
    
       <div v-if="!(order.is_expansion == '1' && order.prev_frame == 'barzel')">
        <div class="lavood_area" v-if="data.frame!='barzel'">
            <div :class="'col-md-' + (order.is_expansion == '1' && lavood != lavood_exp ? '6' : '12') + ' col-xs-12'">
                
                <div :class="'check_box explan ' + (data.is_frame_lavud == true ? 'active' : '')" @click="data.is_frame_lavud=!data.is_frame_lavud; if(data.is_frame_lavud) { data.is_frame_lavud_all=false; }">
                    <div class="pic" :style="'background-image: url(' + $store.siteUrl + 'media/Modules/SHL/pics/5598.jpg)'"></div>
                    <div id="text"><label v-text="'הוספת חוטי לבוד' + (order.is_expansion == '1' ? ' לכל הסוכה' : '')"></label></div>
                    <div id="price"><span>₪</span><label class="bold" v-text="lavood"></label></div>
                </div>
            </div>
            
                <div class="col-md-6 col-xs-12" v-if="order.is_expansion == '1' && lavood != lavood_exp">
                    <div :class="'check_box ' + (data.is_frame_lavud_all == true ? 'active' : '')" @click="data.is_frame_lavud_all=!data.is_frame_lavud_all; if(data.is_frame_lavud_all) { data.is_frame_lavud=false; }">
                        <div class="pic" :style="'background-image: url(' + $store.siteUrl + 'media/Modules/SHL/pics/5598.jpg)'"></div>
                        <div id="text"><label>הוספת חוטי לבוד להרחבה בלבד</label></div>
                        <div id="price"><span>₪</span><label class="bold" v-text="lavood_exp"></label></div>
                    </div>
                    
                </div>
        
            <div class="clr"></div>
            <span :class="'lavood_desc ' + (!(order.is_expansion == '1' && lavood != lavood_exp) ? 'col-md-12' : '')">חוט לבוד להשחלה במוטות הגובה (למעט בדופן הכניסה) וליצירת דופן הלכתית. <a @click="show_desc=true">להסבר מפורט</a></span>

            
        </div>
        </div>
        <div class="clr"></div>
        
        <div class="bottom_area">
            <a @click="$emit('updatePage', 'dimensions')" class="back">חזרה</a>
            <input :class="'button white ' + (isActiveSave ? '' : 'disabled')" :disabled="!isActiveSave" value="המשך" type="submit" @click="save">
        </div>
    </div>
   
</div>
</template>


<script>
export default {
  props: ["order"],
  data() {
      return {
          loaded: false,
          show_desc: false,
          show_desc_frame: false,
          lavood: null,
          lavood_exp: null,
          data: {
              id: this.order.id,
              frame: this.order.frame,
              is_frame_lavud: (this.order.is_frame_lavud == '1'),
              is_frame_lavud_all: (this.order.is_frame_lavud_all == '1'),
              prev_frame_num_barzel: (this.order.prev_frame_num_barzel == '' ? 3 : this.order.prev_frame_num_barzel)
          },
          
      }
  },
  mounted(){
      this.net_api({action: 'frame', data: {id: this.order.id}}, (data) => {
          this.lavood = data.data.lavood;
          this.lavood_exp = data.data.lavood_exp;
          this.loaded = true;
      })
  },
  
  computed:{
      isActiveSave(){
          if (this.order.is_expansion == '1') return true;
          if (this.data.frame == '') return false;
          return true;
      },
      bigPic(){
          if (this.order.is_expansion == '1' && this.order.prev_frame == 'barzel') return 'themes/SHL/images/AKP_0579-half.jpg'
          if (this.order.is_expansion == '1' && this.order.prev_frame == 'mhuzak') return 'media/Modules/SHL/pics/5623_tumb_autoX500.jpg' 
          else
          { 
                switch (this.data.frame)
                {
                    case 'mhuzak': { return 'media/Modules/SHL/pics/5623_tumb_autoX500.jpg';  }
                    case 'barzel': { return 'media/Modules/SHL/pics/5622_tumb_autoX500.jpg';  }
                    case 'masoret': 
                    default: { return 'media/Modules/SHL/pics/5624_tumb_autoX500.jpg' }
                }
              
          }
      }
  },

  methods:{
      save(){
          this.net_api({action: 'order/save_frame', data: this.data}, () => {
            this.$emit('updatePage', 'walls');
            this.$emit('refreshTotal');
        });
      }
        /*
        if (MISC::$GLOBALS["order"]["is_expansion"] == "1" && MISC::$GLOBALS["order"]["prev_frame"] == "barzel")
        {
            $frame = new FormInput_var("frame");
            $frame->value = "barzel";
            $frmForm->push($frame);

            $prev_frame_num_barzel = new FormInput_select("prev_frame_num_barzel");
            $prev_frame_num_barzel->value = 3;
            $prev_frame_num_barzel->set_var("3", "3");
            $prev_frame_num_barzel->set_var("4", "4");
            $frmForm->push($prev_frame_num_barzel);
        }
        */
    }

}

</script>

<style lang="scss" scoped>

#frame 
{
    .side label { margin: 15px 0px; max-width: 80%;}
    .side .button { margin-bottom: 10px; /* white-space: nowrap;*/ /*font-size: 14px */}
    .pic {padding-top: 15px;}
    .pic span {background-position: center;
    
        @media (min-width: 768px) { height: 470px; }
        @media (max-width: 768px) { height: 225px; }
    
    }
    .check_box { margin: 30px auto; margin-bottom: 10px; position: relative; border-radius: 5px;}
	.lavood_desc { display: block; color: #282B34;font-size: 14px;	font-weight: 300;	line-height: 16px; text-align: center; /*margin-top: -25px;*/ padding-left: 15px;  }
	.lavood_desc a { padding: 0px;  font-weight: bold;}
}

#frames_pics { max-width: 827px; text-align: center; padding-top: 20px;}
#frames_pics 
{ 
	.pic { height: 165px;margin-bottom: 10px; }
	label {	font-size: 20px; margin-top: 7x; }
	span {	display: block;font-size: 16px; margin-bottom: 5px; line-height: 16px; padding: 0px 40px;}
}
</style>