<template>
<div style="text-align:center">
    <h1 class="title hide-on-small-only">מחשבון הסוכה של סוכות נחלים</h1>
    <h2 class="description" style="margin-bottom: 40px;">בעזרת מחשבון זה תוכלו לבנות את הסוכה שלכם או להגדיל ולשנות את הסוכה הקיימת. המחשבון יעזור לכם לבחור את חלקי הסוכה שאתם צריכים ולהתאים את הסוכה לפי הצרכים שלכם </h2>

    <h3>חג סוכות שמח</h3>

    <div style="margin-bottom: 20px;">מה תרצו לעשות?</div>
    <a class="button" @click="order" style="margin-left: 10px;">לבנות סוכה חדשה</a>
    <a class="button" @click="expansion" style="margin-left: 10px;">להרחיב סוכה קיימת</a>
    <a class="button" @click="accessories">פריטים בודדים וסוכות ישראל</a>
</div>
    
</template>
<script>

export default {
  //props: ["id"],
  data(){
      return {
          
      }
  },
  methods:{
    order(){
      var self = this;
      
      this.net_api({ action: 'new_order', data: {  }}, function(data){
        self.$emit('new_order',  data.data);

      });
      
    },


    expansion(){
      
      this.net_api({ action: 'new_order', data: { expansion: true }}, (data) => {
        this.$emit('new_order',  data.data);
      });
      
    },


    accessories(){
      
      this.net_api({ action: 'new_order', data: { accessories: true }}, (data) => {
        this.$emit('new_order',  data.data);
      });
      
    }

  }
}
</script>