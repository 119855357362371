import { render, staticRenderFns } from "./dimensions.vue?vue&type=template&id=6475c306&scoped=true&"
import script from "./dimensions.vue?vue&type=script&lang=js&"
export * from "./dimensions.vue?vue&type=script&lang=js&"
import style0 from "./dimensions.vue?vue&type=style&index=0&id=6475c306&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6475c306",
  null
  
)

export default component.exports