import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

//import {store} from './store/store'

import store from './store'

import VueResource from 'vue-resource'
Vue.use(VueResource);
/*
import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)
*/
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'


import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);


import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
//import 'vue-material/dist/theme/default-dark.css'
Vue.use(VueMaterial)


import VueSimpleAlert from "vue-simple-alert"; 
Vue.use(VueSimpleAlert);
/*
import VueNumericInput from 'vue-numeric-input';
Vue.use(VueNumericInput)
*/
/*
import Toasted from 'vue-toasted'; 
Vue.use(Toasted, { position: 'top-left'})
*/

import './general.scss' 
import './app.scss' 
/*
if (window.location.href.indexOf('http://localhost') === -1)  {
  document.domain = "crm.nadlancenter.co.il";
}
*/

Vue.mixin({
 
  data(){
    return {
    }
  },
  computed: {
    user(){
      if(!this.isNull(localStorage.getItem('user_token'))) 
      {
        return true;
      }

      return false;
    },
  },
  methods: {
    alert(str){
      this.$alert(str)
    },
    int(num){
      let ret = parseInt(num);
      if (isNaN(ret)) return 0;
      else return ret;
    },
    get_thumb(id,width,height){
      if (this.isNull(width) && this.isNull(height)) return 'background-image: url(https://build.sukah.co.il/media/Modules/SHL/pics/' + id + '.jpg)';
      else return 'background-image: url(https://build.sukah.co.il/media/Modules/SHL/pics/' + id + '_tumb_' +  width +'X' + height + '.jpg)';
    },
    rod_key_to_name(key){
      switch (key)
      {
        case '1_5': {key = '1.5'; break;}
        case '2_5': {key = '2.5'; break;}
      }

      return key;
    },
    get_word(key){
      return this.$store.words[key];
    },
    is_logged(){
      let user = this.get_me();
      if (this.isNull(user) || !user) return false;
      if (user.id == 99) return false;
      return (user.id > 0);
    },
    get_me(){
      return this.$store.user;
    },
    get_user_name(id){
      return (!this.isNull(this.$store.users[id]) ? this.$store.users[id].display_name : '');
    },
    get_priority_name(id){
      return (!this.isNull(this.$store.prioritiesList[id]) ? this.$store.prioritiesList[id] : '');
    },
    format_number: function(x) {

      //x = x.toString();
      if (x.toString().indexOf('.') != -1)
      {
        console.log(x)
        console.log(parseFloat(x).toFixed(1))
        //x = x.toPrecision(2);
        /*
        x = x.split('.');
        x = x[0] + '.' + Math.round( (x[1][0].toString() + '.' + x[1][1].toString())*1);
        */
      }
      
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    isNull: function (str)
    {
      return (str == '' || str == null || str == undefined);
    },

    parse_date(str){
      var d=new Date(str); return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
    },
    parse_time(str){
      var d=new Date(str); return (d.getHours() < 10 ? '0' + d.getHours() :d.getHours() ) + ':' + (d.getMinutes() < 10 ? '0' + d.getMinutes() :d.getMinutes() )+ ':' +(d.getSeconds() < 10 ? '0' + d.getSeconds() :d.getSeconds() ) ;
    },

   
/*
    get_list_arr(list)
    {
      var ret = [];

    },
*/
  net_api: function(settings, func, failFunc){
    
    // var is_test = false;

    if (this.isNull(func)) func = function(){};

     var headers = {
       'Content-Type': 'application/json; charset=utf-8',
     };
    
     if(!this.isNull(localStorage.getItem('user_token'))) 
     { 
       headers.Authorization = localStorage.getItem('user_token');
     }
   

    // if (!is_test)
    // {
       if (settings.method == 'get')
       {
         this.$http.get(window.api_url + settings.action, /* settings.data,*/ {/*emulateJSON:true,*/ headers: headers})
         .then(response => {
            if (response.body == 'error')
            {
              localStorage.setItem('user_token', '');
              document.location.reload();
            }
             func(response.body);
         }, response => { 
           if (!this.isNull(failFunc)) failFunc(response);
           else func(false);
         });

       }
       else
       {
         this.$http.post(window.api_url + settings.action, settings.data, {/*emulateJSON:true,*/ headers: headers})
         .then(response => {
          if (response.body == 'error')
          {
            localStorage.setItem('user_token', '');
            document.location.reload();
          }
             func(response.body);
             //console.log(response.body);
         }, response => { 
           if (!this.isNull(failFunc)) failFunc(response);
           else func(false);
             //console.error(response.body);
         });
     }
     

   },

    is_mobile: function(){ 

          return ( navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      // || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      );
    },


   }
  })


new Vue({
  router,
  store: store,
  render: h => h(App)
}).$mount('#app')
