import Vue from 'vue'
import VueRouter from 'vue-router'
import CreateOrder from '../views/CreateOrder.vue'
import expansion from '../views/expansion.vue'
import dimensions from '../views/dimensions.vue'
import frame from '../views/frame.vue'
import walls from '../views/walls.vue'
import accessories from '../views/accessories.vue'
import thatch from '../views/thatch.vue'
import summary from '../views/summary.vue'
import pay from '../views/pay.vue'
import print from '../views/print.vue'

Vue.use(VueRouter)

const routes = [

  { path: '/', name: 'CreateOrder', component: CreateOrder },
  { path: '/dimensions/:id', name: 'dimensions', component: dimensions },
  { path: '/expansion/:id', name: 'expansion', component: expansion },
  { path: '/frame/:id', name: 'frame', component: frame },
  { path: '/thatch/:id', name: 'thatch', component: thatch },
  { path: '/accessories/:id', name: 'accessories', component: accessories },
  { path: '/walls/:id', name: 'walls', component: walls },
  { path: '/summary/:id', name: 'summary', component: summary },
  { path: '/pay/:id', name: 'pay', component: pay },
  { path: '/print/:id', name: 'print', component: print },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
