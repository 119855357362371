<template>
<div>
    <numericInp v-model="value_" :min="min" :max="50" :step="0.5" @input="updateVal" @change="$emit('change')" />
    <div class="rods">
        <div class="plan"></div>
        <div class="rods_dropdown" id="length_drop" data-type="length"></div>
    </div>
    
    <div class="plan" v-if="!isNull(plans) && !isNull(activePlan)">
        <label>מורכב מ: 
            <label>
                <template v-for="(num, key) in activePlan.rods" >
                <span :key="key" v-if="num > 0" v-text="num + ' מוטות של ' + rod_key_to_name(key) + ' מטר'"></span>
                </template>
                <a id="plan_change" v-if="plans.length > 1" @click="openOptions=!openOptions">שינוי</a>
            </label>
        </label>
    </div>
    <div v-else>דופן ללא שינוי</div>
    <div class="rods_dropdown" id="width_drop" data-type="width" v-if="openOptions">
        <div id="title">שינוי חלוקת מוטות</div>
        <ul>
            <li v-for="(plan,i) in plans" :key="i" :class="(plan.is_active ? 'active' : '')" @click="selectPlan(plan); openOptions=false">
                <template v-for="(num, key) in plan.rods" >
                <div :key="key" v-if="num > 0" v-text="num + ' מוטות של ' + rod_key_to_name(key) + ' מטר'"></div>
                </template>
            </li>

        </ul>
            
    </div>
</div>
</template>


<script>
import numericInp from './numericInp.vue';
export default {
  props: ["order", "axis", "value", "isPrev", "min", "plansAll"],
  components: { numericInp},
  data() {
      return {
          loaded: false,
        value_: this.value,
        openOptions: false,
        timeout: false
      }
  },
  methods:{
    updateVal: function (value) { 
        this.$emit('input', value)
        this.updateDimensionsTimer();
    },
    
    updateDimensionsTimer(){
        clearTimeout(this.timeout);
        this.timeout = setTimeout(()=> { this.updateDimensions() }, 600 );
    },

    selectPlan(plan){

        for (let i in this.plans) this.plans[i].is_active = false;
        plan.is_active = true;

        this.$emit('change');
        this.save_rods();
/*
        var settings = {
            id: this.order.id,
            width: width[key] 
        }
        
        api_call({ url: 'order/save_rods', data: settings});
        */
    },
    updateDimensions(isInit){
        this.$emit('updateDimensions', isInit);
    },
    save_rods(){
        console.log(this.activePlan)
        var settings = {
            id: this.order.id,
            axis: this.axis,
            data: (!this.isNull(this.activePlan) ? this.activePlan.rods : [])
        }
        this.net_api({ action: 'order/save_rods' + (this.isPrev === true ? '_prev' : ''), data: settings}, () => {
            
            this.$emit('refreshTotal');
        });

    }
  },
  computed:{
      plans(){
 
          return this.plansAll[this.axis];
      },
      activePlan()
      {
          let ret;
          //if (this.isNull(this.plans)) return this.order.rods_plan[this.axis];

          ret = this.plans[Object.keys(this.plans)[0]]



          for (let i in this.plans)
          {
              if (this.plans[i].is_active)
              {
                  ret = this.plans[i];
                  break; 
              }
          }
          
          return ret;
      }
  },

}
</script>

<style lang="scss" scoped>

</style>