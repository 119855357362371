
<template>
    <div v-if="loaded">
        <iframe style="width:100%; height:800px; border:none;" allowtransparency="true" frameborder="0" scrolling="no" :src="this.$store.siteUrl + 'payments/do_redirect/' + payment_id"></iframe>

    </div> 
</template>

<script>
export default {
    props: ["order"],
    data(){
        return {
            loaded: false,
            payment_id: 0
        }
    },
    mounted(){
        
          this.net_api({action: 'pay', data: {id: this.order.id}}, (data) => {
              this.payment_id = data.data.payment_id;

              this.loaded = true;
        })
    }
}
</script>

<style lang="scss" scoped>

</style>