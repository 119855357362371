<template>
        <div v-if="loaded">

    <md-dialog :md-active.sync="show_desc">
      <md-dialog-title>מה ההבדל בין הסככים?</md-dialog-title>

      <div class="pop_info_inner" v-html="desc">
          
      </div>

      <md-dialog-actions>
        <md-button class="md-primary" @click="show_desc = false">סגירה</md-button>
      </md-dialog-actions>
    </md-dialog>

    <div id="thatch">
        
<div class="row">

<div class="col-md-4 col-xs-12 side">

<h1 class="title2">בחירת סכך</h1>

<div :class="'button radio ' + (data.thatch=='rafya' ? 'active' : '')" @click="data.thatch='rafya'; tatch_make_plans_and_save()">סכך רפיה</div>
<div :class="'button radio ' + (data.thatch=='suf' ? 'active' : '')" @click="data.thatch='suf'; tatch_make_plans_and_save()">סכך קני סוף</div>

<a @click="show_desc=true" class="bold">מה ההבדל בין הסככים?</a>
 
<div style="margin-top: 20px;" v-if="!isNull(activePlan)">

    <div class="plan" id="method">

        <label>מורכב מ: </label>

        <div v-for="planLine in activePlan.items" :key="planLine.id">
            <div v-if="planLine.count > 0">{{planLine.count}} סככים של {{planLine.dim.x}}x{{planLine.dim.y}} מטר</div>
        </div>
        <div><strong>מחיר</strong>: ₪{{calcPrice(activePlan)}}</div>
        <a id="plan_change" v-if="Object.keys(plans).length > 1" @click="openOptions=!openOptions">שינוי</a>

    </div>
    <div class="rods_dropdown" id="width_drop" data-type="width" v-if="openOptions">
        <div id="title">שינוי חלוקת סכך</div>
        <ul>
            <li v-for="(plan,i) in plans" :key="i" :class="(plan.is_active ? 'active' : '')" @click="selectPlan(plan); openOptions=false">
                <div v-for="planLine in plan.items" :key="planLine"><div v-if="planLine.count > 0">{{planLine.count}} סכך של {{planLine.dim.x}}x{{planLine.dim.y}} מטר</div></div> 
            </li>
        </ul>
    </div>
</div>

</div>

<div class="col-md-8 col-xs-12">

    <div class="pic" :key="data.thatch"><span :style="get_thumb(typePic, 'auto', 400)"></span></div>

</div>

</div>
<div class="clr"></div>
<div class="row" style="text-align: right;">

<div style="text-align:center; padding-top:25px"><label>בחר תוספת</label></div>

<div class="line">

    <div :class="'col-md-' + (bambook_price2 > 0 ? '4' : '6') + ' col-xs-12'">

        <div :class="'check_box bambook_radio ' + (data.is_thatch_bambuk ? 'active' : '')" @click="data.is_thatch_bambuk=!data.is_thatch_bambuk">
            <div class="pic" :style="get_thumb(5626)"></div>
            <div id="text">
                <label>לייסטים להחזקת הסכך</label>

                <span>
                    <div v-if="bambook_data.num_2 > 0">במבוק 2.2 מ': {{bambook_data.num_2}} יח'</div>
                    <div v-if="bambook_data.num_3 > 0">במבוק 3.2 מ': {{bambook_data.num_3}} יח'</div>
                    <div v-if="bambook_price2 > 0">ללא שימוש בבמבוקים 3.2 מהסוכה המקורית</div>
                </span>

            </div>
            <div id="price"><span>₪</span><label class="bold" v-text="bambook_price"></label></div>
        </div>

    </div>

    <div v-if="bambook_price2 > 0" :class="'col-md-' + (bambook_price2 > 0 ? '4' : '6')  + ' col-xs-12'">

    <div :class="'check_box bambook_radio ' + (data.is_thatch_bambuk2 ? 'active' : '')" @click="is_thatch_bambuk2=!is_thatch_bambuk2">
    <div class="pic" :style="get_thumb(5626)"></div>
    <div id="text"><label>במבוקים להחזקת הסכך</label>
    <span>
    <div v-if="bambook_data.num_2_ver2 > 0">במבוק 2.2 מ': {{bambook_data.num_2_ver2}} יחידות</div>
    <div v-if="bambook_data.num_3_ver2 > 0">במבוק 3.2 מ': {{bambook_data.num_3_ver2}} יחידות</div>
    <div>עם שימוש בבמבוקים 3.2 מהסוכה המקורית</div>
    </span>
    </div>
    <div id="price"><span>₪</span><label class="bold" v-text="bambook_price2"></label></div>
    </div>

    </div>

    <div :class="'col-md-' + (bambook_price2 > 0 ? '4' : '6') + ' col-xs-12'">

    <div :class="'check_box ' + (data.is_thatch_bambuk_ties==1 ? 'active' : '')" @click="data.is_thatch_bambuk_ties=!data.is_thatch_bambuk_ties">
    <div class="pic" :style="get_thumb(5625) + '; background-size: 182%; background-position: center center;'"></div>
    <div id="text"><label>חבקים להחזקת הלייסטים</label>

        <span>
        <div>{{bambook_data.hevek_units}} יח'</div>
        </span>
    </div>
    <div id="price"><span>₪</span><label class="bold" v-text="hevek_price"></label></div>
    </div>

    </div>

</div>



</div>


    <div class="col-md-12 col-xs-12">

        <div class="bottom_area">

            <a @click="$emit('updatePage', 'walls')" class="back">חזרה</a>
                <input :class="'button white ' + (isActiveSave ? '' : 'disabled')" :disabled="!isActiveSave" value="המשך" type="submit" @click="save">

        </div>

    </div>
            
    

    </div> 
    </div> 
</template>

<script>
export default {
    props: ["order"],
    data(){
        return {
            loaded: false,
            show_desc: false,
            desc: '',
            openOptions: false,
            plans: {},
            bambook_data: {},
            bambook_price: '',
            bambook_price2: '',
            hevek_price: '',
            data: {
                id: this.order.id,
                thatch: this.order.thatch,
                is_thatch_bambuk: (this.order.is_thatch_bambuk == '1'),
                is_thatch_bambuk2: (this.order.is_thatch_bambuk2 == '1'),
                is_thatch_bambuk_ties: (this.order.is_thatch_bambuk_ties == '1'),
                thatch_method: this.order.thatch_method

            }
        }
    },
    computed:{
        
      isActiveSave(){

          //if (this.data.thatch == '') return false;
          return true;
      },
        typePic(){

            var ret = 5627;
            if (this.data.thatch == 'rafya') ret = 5627;
            else if (this.data.thatch == 'suf') ret = 5628;

            return ret;
        },
        activePlan(){
                
            let ret;
    
            ret = this.plans[Object.keys(this.plans)[0]];

            for (let i in this.plans)
            {
                if (this.plans[i].is_active)
                {
                    ret = this.plans[i];
                    break; 
                }
            }
       
            return ret;
        }
    },
    methods:{
        tatch_make_plans_and_save(){
            
            this.net_api({action: 'order/save_thatch', data: this.data}, () => {
                
                this.tatch_make_plans();
                this.$emit('refreshTotal');
            });

        },
        save(){
            if (this.data.thatch == '')
            {
                if (!confirm ('לא בחרת סכך, האם להמשיך?')) return;
            }

            this.net_api({action: 'order/save_thatch', data: this.data}, () => {
                this.$emit('updatePage', 'accessories');
                
                this.$emit('refreshTotal');
            });
        },
        selectPlan(plan){

            for (let i in this.plans) this.plans[i].is_active = false;
            plan.is_active = true;
            let selectedI;
            for (let i in this.plans)
            {
                if (this.plans[i].is_active) 
                {
                    selectedI = i;
                    break;
                }
            }


            this.data.thatch_method = selectedI; 
            
            this.net_api({action: 'order/update_field', data: {id: this.order.id, field:'thatch_method', value: this.data.thatch_method}}, () => {
                this.$emit('refreshTotal');
            });

        },
        calcPrice(plan){
            let price = 0;

            for (let i in plan.items) price += plan.items[i].price * plan.items[i].count;

            return price;
        },
        tatch_make_plans() {
            this.net_api({ action: 'order/get_thatch_plans', data: { id: this.order.id }}, (data) =>{

                //for (let i in data.data) data.data[i] = {is_active: false, items: data.data[i]};

                this.plans = data.data;
            });  

        }
        
    },
    mounted(){
        //this.tatch_make_plans();
          this.net_api({action: 'thatch', data: {id: this.order.id}}, (data) => {
              this.bambook_data = data.data.bambook_data;
              this.bambook_price = data.data.bambook_price;
              this.hevek_price = data.data.hevek_price;
              this.desc = data.data.desc;

                this.tatch_make_plans();

              this.loaded = true;
        })
    }
}



    
//     $('#thatch').on("click","#plan_change", function(){

//         $(".plan").hide();
//         $(".rods_dropdown").show();

//     });
    
//     $('input[name="thatch"]').on('change', function(){
       
//         settings.field = "thatch";
//         settings.value = $(this).val();

//         api_call({ url: 'order/update_field', data: settings});
//         calc_total();
//         tatch_make_plans(); 
//     })
    
//      //select new plan
//     $('#thatch').on("click", '.rods_dropdown ul li', function(){

//             var dropdown = $(".rods_dropdown ul li").removeClass("active");
//             $(this).addClass("active");

//             var key = $(this).data("key");

//             var order_id = '{MISC::$GLOBALS["id"]}';

//             settings.field = "thatch_method";
//             settings.value = key;

//             make_plan( plan[key], "#method");
//             api_call({ url: 'order/update_field', data: settings, onComplete: function(){
//                 calc_total();
//             }});

//             $(".plan").show();
//             $(".rods_dropdown").hide();

//         });

       

    
//     $('input[name="is_thatch_bambuk"], input[name="is_thatch_bambuk2"], input[name="is_thatch_bambuk_ties"]').on('change', function(){
        
//             settings.field = $(this).attr('name');
//             settings.value = $(this).val();

//             api_call({ url: 'order/update_field', data: settings});
        
//     });
    
    

// });

</script>

<style lang="scss">

#thatch_pics { max-width: 575px; text-align: center; padding-top: 20px;}
#thatch_pics 
{ 
	.pic { height: 165px;margin-bottom: 10px;    background-size: 170%;background-position: 65% 24%; }
	label {	font-size: 20px; margin-top: 7x; }
	span {	display: block;font-size: 16px; margin-bottom: 5px; line-height: 16px; padding: 0px 40px;
    }
}

#thatch .title2 { margin-bottom: 25px;}
#thatch .pic {padding-top: 15px;}
#thatch .pic span {
        @media (min-width: 768px) { height: 350px; }
        @media (max-width: 768px) { height: 225px; }
    }




</style>