<template>
  <div id="app" :class="'rtl'">
    
    <main id="page-wrap" v-if="loaded" :class="(show_login ? 'no_header' : '')">

      <div id="page_content">
        <LoginPage v-if="show_login" />
        
        <template v-if="!show_login">
            <Header v-if="order.id > 0" :order="order" />
            
            <Bar :order="order" ref="bar" />

            <div id="content"><router-view :key="$route.path + pageKey" @show_loader="loaded=false" @loaded="loaded=true" @updateSummary="updateSummary" @new_order="getOrder($event, true)" @updatePage="updatePage" :order="order" @refreshTotal="$refs.bar.init()" ></router-view></div>
            
        </template>
      </div>

    </main>
    
    <div v-if="!loaded" id="app_loading">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>

  </div>
</template>

<script>

import Header from './components/header.vue'
import LoginPage from './views/login.vue'
import Bar from './components/bar.vue'

window.api_url = 'https://build.sukah.co.il/api_vue/'; 

export default {
  name: 'app',
  components: {
    Header,LoginPage, Bar
  },
  data(){
    return {
      user_data: {},
      show_login: false,
      loaded: false,
      order: {},
      pageKey: 0
    }
    
  },
  methods: {
    updatePage(page){

      this.getOrder(this.order.id, false, () => { 
        this.$router.replace('/' + page + '/' + this.order.id); 
      })
      
    },
    updateSummary(){
      this.net_api({ action: 'order/get', data: {id: this.order.id} }, (data)=> {
        this.order = data.data;
        //this.pageKey++;
      })
    },
    getOrder(id, move, func){

      //amitai code check if loged to get id
      // if(this.is_logged())
      // {
      //     let urlData = {id: this.order.id}; 
      // }

      let urlData = {id: id};
      if (!this.isNull(this.$route.query.token)) urlData.token = this.$route.query.token;

      this.net_api({ action: 'order/get', data: urlData }, (data)=> {
        this.order = data.data;
        this.loaded = true;
/*
        if ($_GET["expansion"] == "1") $go = "home/expansion/";
        //elseif ($_GET["accessories"] == "1") $go = "home/accessories/";
        else $go = "home/dimensions/";
  */

        if (move === true)
        {
          if (this.order.is_accessories== '1') this.$router.replace('/accessories/' + this.order.id);
          else if (this.order.is_expansion == '1') this.$router.replace('/expansion/' + this.order.id);
          else 
          {
            this.$router.replace('/dimensions/' + this.order.id); 
            
            //document.location = '/dimensions/' + this.order.id; 
          }
        }

        if (!this.isNull(func)) func();

      } );
    },
    initGeneral(){
      this.net_api({ action: 'general' }, (data) => {

        this.user_data = data.data.user; 
        this.$store.user = data.data.user;
        this.$store.words = data.data.words;
        this.$store.ref_sources = data.data.ref_sources;
     
        if (this.isNull(this.user_data) || !(this.user_data.id > 0))
        {
          localStorage.setItem('user_token', '');
          this.show_login = true;
        }

        if (!this.isNull(this.$route.params.id)) this.getOrder(this.$route.params.id);
        else this.loaded = true;

      } );
    }
  },
  mounted() {

    window.appVue = this;

    this.$store.siteUrl = 'https://build.sukah.co.il/';

    if (localStorage.getItem('user_token') == 'website' && this.$route.name != 'summary' && this.$route.name != 'pay' && this.$route.name != 'print')
    {
      localStorage.setItem('user_token', '');
    }
    else if (!this.user && this.$route.name == 'summary' && !this.isNull(this.$route.params.id) && !(this.$route.params.id > 0) )
    {
      this.getOrder(this.$route.params.id, false, () => {
        //if (this.order.source == 'site')
        //{
          localStorage.setItem('user_token', 'website');
  
          document.location.reload()
          //this.initGeneral();
        //}
      });
      
    }

    if (!this.user)
    {
      this.show_login = true;
      this.loaded = true; 
    }
    else
    {
      this.initGeneral();
    }
  },/*
  watch: {
    id(){
      alert(this.id);
    }
  }*/
}
</script>